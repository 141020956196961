import React, { Component } from "react";
import { PhoneCall } from "react-feather";
import { FaWhatsapp, FaCopy, FaCheckDouble } from "react-icons/fa";
import { formatarTelefone } from "../../../helpers/FuncHelpers";

export default class DadosCadastrais extends Component {
  render() {
    const { phones, representanteLegalContato } = this.props
    return (
      <>

        <div className="col-sm-12 col-xl-6">
          <div className="card">
            <div className="card-header b-l-primary">
              <h5> <PhoneCall size={15} /> TELEFONES</h5>
            </div>
            <div className="card-body" style={{ padding: '25px' }}>
              {representanteLegalContato && (representanteLegalContato.map((p, index) => {
                return (<>
                  <div className="d-flex justify-content-between align-items-center ficha_item">
                    <div className="ficha_item_title">
                      <span>TELEFONE (REPRESENTANTE LEGAL) {index + 1}</span>
                    </div>
                    <div className="ficha_item_desc">
                      <span>{p.tipo}</span>
                    </div>
                    <div className="ficha_item_desc">
                      <span>{formatarTelefone(p.numeroLinha)}</span>
                      <span style={{ marginLeft: '10px' }}>{p.whatsapp && (<>
                        <a target="_blank" href={`https://wa.me/${p.numeroLinha}`} className="btn btn-xs btn-success"><i className="fa fa-whatsapp"></i></a>
                      </>)}</span>
                    </div>
                  </div>
                </>)
              })
              )}

              {phones && (phones.map((p, index) => {
                return (<>
                  <div className="d-flex justify-content-between align-items-center ficha_item">
                    <div className="ficha_item_title">
                      <span>TELEFONE {index + 1}</span>
                    </div>
                    <div className="ficha_item_desc">
                      <span>{p.tipo}</span>
                    </div>
                    <div className="ficha_item_desc">
                      <span>{formatarTelefone(p.numeroLinha)}</span>
                      <span style={{ marginLeft: '10px' }}>{p.whatsapp && (<>
                        <a target="_blank" href={`https://wa.me/${p.numeroLinha}`} className="btn btn-xs btn-success"><i className="fa fa-whatsapp"></i></a>
                      </>)}</span>
                    </div>
                  </div>
                </>)
              })
              )}

              {(phones && representanteLegalContato) && <p style={{ padding: "15px" }}>Desculpe, mas esse cliente não possui telefones em nossa base de dados</p>}
            </div>
          </div>
        </div>
      </>
    );
  }
}
