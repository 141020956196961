import { Component } from 'react';
import styled from 'styled-components';

export class BadgeTypeMailing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            colorsBadge: [],
            typeModel: ''
        };
    }

    componentDidMount() {
        this.getColorsBadge();
        this.getModelName();
    }

    getColorsBadge = () => {
        const { typeMailing } = this.props;
        const types = {
            //color bg | color text (if none, default is white)
            'cartao': ['#fd7e14', '#212529'],
            'margem': ['#0dcaf0'],
            'portabilidade': ['#FFC107', '#212529'],
            'todos': ['#198754'],
            'seguro_vida': ['#198754'],
            'data_mailing': ['#198754'],
            'aumento_margem': ['#B02A37'],
            'entrantes': ['#3D0A91'],
            'cartao_beneficio': ['#AB296A'],
        };
        const colors = types[typeMailing];

        this.setState({
            colorsBadge: colors
        })
    }

    getModelName = () => {
        const { typeMailing } = this.props;
        console.log('...........', typeMailing)
        const types = {
            //color bg | color text (if none, default is white)
            'cartao': 'Cartão',
            'margem': 'Margem',
            'portabilidade': 'Portabilidade',
            'seguro_vida': 'Seguro de Vida',
            'todos': 'Todos',
            'data_mailing': 'Todos',
            'aumento_margem': 'Aumento de Margem',
            'entrantes': 'Entrantes',
            'cartao_beneficio': 'Cartão Benefício',
        };
        const type = types[typeMailing];

        this.setState({
            typeModel: type
        })
    }

    render() {
        const { typeMailing } = this.props;
        const { colorsBadge, typeModel } = this.state;
        const Badge = styled.span`
            background: ${colorsBadge[0] ? colorsBadge[0] : '#000'};
            color: ${colorsBadge[1] ? colorsBadge[1] : '#FFF'};
        `;
        return (
            <>
                {typeMailing
                    ? <>
                        <Badge className='badge text-uppercase fw-lighter'> {typeModel} </Badge>
                    </>
                    : ''}
            </>
        );
    }
}