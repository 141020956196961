import React, { Component } from "react";
import PageTitle from "../../helpers/PageTitle";
import { startLoad, stopLoad } from "../../messages/load";
import { ApiRequest } from "../../helpers/Api";
import { Table, Tr, Td } from "../../helpers/Table";
import MessageDisplay from "../../messages/messageDisplay";
import Atendiment from "../callcenter/atendiment";

export default class AtendimentManual extends Component {
    constructor(props) {
        super(props)
        this.state = {
            auth: JSON.parse(localStorage.getItem('user')),
            data: '',
            newComponent: ''
        }
    }
    componentDidMount() {
        this.getMailingsList();
    }

    async getMailingsList() {
        startLoad();
        const api = new ApiRequest();
        const result = await api.get('get-all-models')

        if (result) {
            this.setState({
                data: result.data
            })
        }
    }

    async atendimentManulClient(e) {
        const id = e.target.id
        const api = new ApiRequest();
        const msg = new MessageDisplay();
        const result = await api.get(`get-client-atendiment-manual/${id}`);

        startLoad();
        if (result.error === true && result) {
            stopLoad();
            msg.error('Mailings', result.data)
            return;
        }

        stopLoad();
        this.setState({
            newComponent: <Atendiment
                data={result.data} mailing={id}
            />
        })
        msg.success('Atendimento', 'Próximo cliente localizado com sucesso')
        return;

    }


    render() {
        const { auth, data, newComponent } = this.state
        return (
            <>
                {newComponent ? newComponent : (<>
                    <div className="page-body">
                        <div className="container-fluid">
                            <PageTitle title={'Atendimento Manual'} auth={auth} />
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5>Atendimento Manual</h5>
                                        </div>

                                        {data && data.length > 0 ? data.map((md) => {
                                            return (
                                                <>
                                                    <div class="alert alert-primary dark" role="alert">
                                                        <p>{md.name}</p>
                                                    </div>
                                                    <Table>
                                                        <Tr>
                                                            <Td classTd={"text-center"}>
                                                                Id
                                                            </Td>
                                                            <Td classTd={"text-center"}>
                                                                Total
                                                            </Td>
                                                            <Td classTd={"text-center"}>
                                                                Trabalhar
                                                            </Td>
                                                        </Tr>
                                                        {md?.mailings && md?.mailings?.length > 0 ? md?.mailings.map((m) => {
                                                            return (<>

                                                                <Tr>
                                                                    <Td classTd={"text-center"}>
                                                                        {m._id}
                                                                    </Td>
                                                                    <Td classTd={"text-center"}>
                                                                        {m.total_abastecido}
                                                                    </Td>
                                                                    <Td classTd={"text-center"}>
                                                                        <button id={m._id} onClick={(e) => { this.atendimentManulClient(e) }} className="btn btn-sm btn-danger">
                                                                            Atender Clientes

                                                                        </button>
                                                                    </Td>
                                                                </Tr>
                                                            </>)

                                                        }) : 'Desculpe você não possui mailings ativos'}
                                                    </Table>
                                                </>
                                            )
                                        }
                                        )
                                            : 'Desculpe você não possui mailings ativos'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </>)}
            </>

        )
    }
}