/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import PageTitle from "../../helpers/PageTitle";
import { Table, Tr, Td } from "../../helpers/Table";
import MessageDisplay from "../../messages/messageDisplay";
import { startLoad, stopLoad } from "../../messages/load";
import Modal from 'react-bootstrap/Modal';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import sweetAlert from "../../helpers/sweet";
import FilterMailing from "./filter/filter_mailing"
import * as XLSX from 'xlsx';
import { BadgeTypeMailing } from './generic_components/badge-type-mailing';
import { BadgeConvenioMailing } from './generic_components/badge-convenio-mailing';
import { NotFoundRobot } from '../../animations/not-found-robot';
import MessageScreen  from "../../messages/messageScreen";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { AnimationLottie } from "../../animations/animationLottie";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowsRotate} from '@fortawesome/free-solid-svg-icons'

import {
    formatarDataHora,
} from "../../helpers/FuncHelpers";
import { SpinnerCircular } from "spinners-react"
import { ApiRequest } from "../../helpers/Api";
import { data } from "jquery";
import { DialerEnum } from "../../../core/enum/DialerEnum";

export default class Mailing extends Component {
    constructor(props) {
        super(props)
        const { interval } = this.props
        this.state = {
            auth: JSON.parse(localStorage.getItem('user')),
            listModelsMailing: [],
            intervalId: interval,
            show: false,
            nameModal: false,
            subtitleModal: '',
            typeModal: false,
            dataModal: false,
            mailingsListData: '',
            dataIdModal: false,
            showMessageScreenTeamNotParam: false,
            showMessageScreenUploadingDialer: false,
            showMessageScreenUploadDialerDone: false,
            showMessageScreenUploadDialerError: false,
        }
        this.intervalId = null; // Adicione esta linha
        this.handleShow = this.handleShow.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.absMailing = this.absMailing.bind(this)
        this.createMailing = this.createMailing.bind(this)
        this.clearFilter = this.clearFilter.bind(this)
        this.deleteModel = this.deleteModel.bind(this)
        // this.uploadMailingToDialer = this.uploadMailingToDialer.bind(this)
        this.markAsSentToDialer = this.markAsSentToDialer.bind(this)
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown)
        document.addEventListener('click', this.documentClickListener);
        this.getModelos(true);
        // busca os dados a cada 15 segundos
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
        document.removeEventListener('click', this.documentClickListener);
        clearInterval(this.state.intervalId);
    }

    handleDocumentClick(event) {
        if (!this.elementRef.current.contains(event.target)) {
            clearInterval(this.state.intervalId)
        }
    }

    filterMailing(e) {
        const mailing = e.target.id;
        this.setState({
            newComponemteFilter: true
        })
    }

    async deleteModel(e) {
        const msg = new MessageDisplay();
        const api = new ApiRequest();
        const sweet = new sweetAlert();

        const value = e.target.closest('.btn')
        const id = value.id
        const data = id.split(',')

        const postData = {
            _id: data[0],
            type_mailing: data[1]
        }
        sweet.warning('ATENÇÃO: Esta ação irá deletar o modelo e todos os mailings criados dentro dele. Tem certeza de que deseja continuar?', null, null, 'Confirmar').then(async (e) => {
            if (e === true) {
                const result = await api.post('delete-model', postData, false)

                if (result.error === true && result) {
                    msg.error('Modelo', result.data)
                    return;
                }
                msg.success('Modelo', 'Modelo deletado com sucesso')
                await this.getModelos(false)
            }
        })
    }

    async absMailing(e) {
        const msg = new MessageDisplay();
        const api = new ApiRequest();

        const value = e.target.closest('.btn')
        const type_mailing = value.getAttribute('data-type')
        console.log('>>>>>>>>>>>>>>>> ', type_mailing)
        const id = value.id
        const postData = {
            mailing: id,
            type_mailing: type_mailing
        }

        const result = await api.post('abs-mailing', postData, false)
        if (result.error === true && result) {
            msg.error('Erro!', result.data)
            return;
        }

        const mailings = this.state.mailingsListData.mailings.map(item => ({
            ...item,
            status: item._id === postData.mailing ? "abastecendo" : item.status
        }))

        this.setState({
            show: false,
            mailingsListData: {
                ...this.state.mailingsListData,
                mailings: mailings
            }
        });



        this.getModelos();
        msg.success('Sucesso', 'Mailing enviado com sucesso para abastecimento')
        return;

    }

    async getModelos() {
        console.log('pegando modelos')
        const msg = new MessageDisplay();
        const api = new ApiRequest();

        const result = await api.get(`get-all-models`);
        if (result.error === true && result) {
            msg.error('Modelo', result.data)
            return;
        } else {
            this.setState({
                listModelsMailing: result.data,
            })
        }
    }

    async markAsSentToDialer(e, action = true) {
        startLoad();
        const value = e.target.closest('.btn')
        const id = value.id
        const api = new ApiRequest();
        const msg = new MessageDisplay();

        const postData = {
            idMailing: id,
            action: action
        }

        const result = await api.post(`mark-mailing-send-to-dialer`, postData, false)
        if (result.error === true && result) {
            msg.error('Erro ao sinalizar mailing!', result.data)
            return;
        }

        const message = action ? 'Mailing sinalizado como ENVIADO' : 'Mailing sinalizado como NÃO ENVIADO'
        msg.success(message);
        this.setState({
            mailingsListData: result.data,
        });
        this.handleClose();
    }

    async verifyTeamDialerBeforeUploadDialer(event){
        startLoad();
        const api = new ApiRequest();
        const msg = new MessageDisplay();

        const value = event.target.closest('.btn')
        const idMailing = value.id
        
        const result = await api.get(`getTeamMailing/${idMailing}`, false)
        stopLoad();
        
        if (result.error === true && result) {
            msg.error('Ocorreu um erro ao buscar informações da sua equipe!', result.data)
            return;
        }

        const dialer = result.data.discadora?.value;

        if (!dialer)
            this.setState({ showMessageScreenTeamNotParam: true }); 

        switch (dialer) {
            case DialerEnum.Callix:
                this.openModalSelectCampaign(idMailing, dialer)
                break;
            default:
                this.uploadMailingToDialer(idMailing, dialer);
        }
    }

    async openModalSelectCampaign(idMailing, dialer) {
        const api = new ApiRequest();

        this.setState({
            show: true,
            nameModal: 'Subir contatos para discadora',
            subtitleModal: `Escolha para subir seus contatos para ${dialer}`,
            typeModal: 'selectCampaign',
            dataModal: null,
            dataIdModal: null
        })

        const result = await api.get(`getCampaignsDialerByMailing/${idMailing}`, false);
        if (result.error === true && result) {
            this.setState({ dataModal: {error: true, dialer: dialer, idMailing } }); 
            return
        }
        const dataCampaignsDialer = result.data;
        this.setState({ dataModal: {error: false, dialer: dialer, data: dataCampaignsDialer, idMailing}  }); 
    }

    async uploadMailingToDialer(idMailing, dialer, campaign = null) {
        const sweet = new sweetAlert();
        let textMessage = `Seus contatos serão importados na discadora que foi parametrizada em sua equipe. Discadora: ${dialer}`;
        textMessage = campaign != null ? `${textMessage} <b>Campanha: ${campaign?.attributes?.name}</b>` : textMessage;

        sweet.question(textMessage, null, null, 'Confirmar')
            .then(async (e) => {
                if (e === true) {
                    this.setState({ showMessageScreenUploadingDialer: true  });

                        const api = new ApiRequest();
                        const msg = new MessageDisplay();

                        const postData = {
                            idMailing: idMailing,
                            idCampaign: campaign != null ? campaign.id : null,
                        }

                        const result = await api.post(`upload-mailing-to-dialer`, postData, false)

                        if (result.error === true && result) {
                            msg.error('Erro ao subir mailing para discadora!', result.data)
                            this.setMessagesScreenToFalse();
                            this.setState(
                                { showMessageScreenUploadDialerError: true }
                            );
                            return;
                        }

                        msg.success('Sucesso', result.data);
                        this.handleClose();
                        this.setMessagesScreenToFalse();
                        this.setState(
                            { showMessageScreenUploadDialerDone: true }
                        );
                    return;
                }
            })  
    }

    async downloadExcel(e) {
        startLoad();
        const value = e.target.closest('.btn')
        const id = value.id
        const api = new ApiRequest();
        const msg = new MessageDisplay();

        const postData = {
            id: id
        }

        const result = await api.post(`generate-mailing-csv`, postData, false)
        if (result.error === true && result) {
            msg.error('Erro ao gerar excel', result.data)
            return;
        }

        const dados = [
            ['identificador', 'nome', 'telefone1', 'telefone2', 'telefone3', 'telefone4', 'telefone5', 'telefone6'],
            ...result.data.map((c) => [c.id, c.client_name, c.phones[0], c.phones[1], c.phones[2], c.phones[3], c.phones[4], c.phones[5]])
        ];

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(dados);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Dados');

        const excelBuffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });

        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `mailing_${id}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        stopLoad();
    }

    handleClose() {
        this.setState({
            show: false
        })
    }

    handleClickOutside = (event) => {
        if (this.buttonRef.current && !this.buttonRef.current.contains(event.target)) {
            this.setState({ isClicked: false });
        }
    }

    async mailingStatus() {
        const data = document.querySelectorAll('[data-status]')
        let statusMailing = false;

        for (const e of data) {
            const status = e.getAttribute('id');
            if (status === 'abastecendo') {
                statusMailing = true
            }
        }

        const blockButtonAbs = document.querySelectorAll('.dataAbsMailing')

        if (statusMailing) {
            blockButtonAbs.forEach((e) => {
                e.style.pointerEvents = 'none';
                const icon = e.querySelector('i')
                const span = e.querySelector('span')
                if (span != null) {
                    span.innerText = 'AGUARDE ATÉ O OUTRO MAILING ABASTECER'
                    icon.className = 'fa fa-clock-o'
                }
            })
        } else {
            blockButtonAbs.forEach((e) => {
                e.style.pointerEvents = '';
                const icon = e.querySelector('i')
                const span = e.querySelector('span')
                if (span != null) {
                    span.innerText = 'ABASTECER MAILING'
                    icon.className = 'fa fa-upload'
                }
            })
        }
    }

    async handleShow(e) {
        const value = e.target.closest('.btn')
        const type = value.getAttribute('data-type')
        const typeMailing = value.getAttribute('data-type-mailing')
        const idMailing = value.getAttribute('data-id')
        let name = '';
        let subtitle = '';
        switch (type) {
            case 'filterModel':
                name = 'Filtro do Modelo'
                subtitle = idMailing
                break;
            case 'actionMailing':
                name = 'Ações do Mailing'
                break;
            default:
                name = '';
        }

        this.setState({
            show: true,
            nameModal: name,
            subtitleModal: subtitle,
            typeModal: type,
            dataModal: value.id ? JSON.parse(value.id) : null,
            dataIdModal: idMailing,
            typeMailing: typeMailing
        }, () => {
            this.mailingStatus();
        })
    }

    async createMailing(e) {
        const value = e.target.closest('.btn')
        const type = value.getAttribute('data-type')
        const id = value.id
        const postData = {
            id: id,
            type
        }
        const api = new ApiRequest();
        const msg = new MessageDisplay();
        const result = await api.post(`create-mailing-all`, postData, false)
        if (result.error === true && result) {
            msg.error('Mailing', result.data)
            return;
        }
        this.setState({
            mailingsListData: result.data
        })

        await this.getModelos()

        msg.success('Sucesso!', 'Novo mailing criado. Abasteça para utilizar')
        return;
    }

    async deleteMailing(e) {
        const value = e.target.closest('.btn')
        const type = value.getAttribute('data-type')
        console.log(type)
        const id = value.id
        const msg = new MessageDisplay();
        const sweet = new sweetAlert();
        const postData = {
            id: id,
            type
        }
        const api = new ApiRequest()
        this.setState({ show: false })

        sweet.info('Deseja realmente deletar esse mailing', null, null, 'Confirmar')
            .then(async (e) => {
                if (e === true) {
                    startLoad();
                    const result = await api.post(`delete-mailing`, postData, false)
                    if (result.error === true && result) {
                        msg.error('Modelo', result.data)
                        return;
                    }
                    this.setState({
                        mailingsListData: result.data,
                    })
                    stopLoad();
                    msg.success('Sucesso', 'Mailing foi deletado')
                    return;
                }
            })
    }

    async getMailingsList(value) {
        console.log(value)
        const api = new ApiRequest();
        this.setState({
            intervalId: setInterval(async () => {
                const result = await api.get(`get-result-mailings/${value._id}/${value.type_mailing}`, false);
                console.log(result)
                if (result.error === true && result && result === 'false') {
                    return;
                } else {
                    this.setState({
                        mailingsListData: result.data,
                    });
                }
            }, 15000)
        })

    }

    async viewMailings(e) {
        const value = e.target.closest('.btn')
        const data = JSON.parse(value.id)
        console.log(data)
        const postData = {
            _id: data._id,
            type_mailing: data.type_mailing
        }

        this.getMailingsList(postData)

        if (postData) {
            const close = document.getElementById('close');
            close.classList.add('open');
            close.style.width = "60%";
        }


        this.setState({
            mailingsListData: data
        })

    }

    handleKeyDown = async (event) => {
        if (event.keyCode === 27) { // 27 é o código da tecla "ESC"
            clearInterval(this.state.intervalId);
            await this.getModelos();
            const close = document.getElementById('close');
            close.classList.remove('open');
            close.style.width = "0px";
        }
    }

    async clearFilter(e) {
        const api = new ApiRequest();
        const msg = new MessageDisplay();
        const sweet = new sweetAlert();
        const value = e.target.closest('.btn')
        const data = JSON.parse(value.id)

        const postData = {
            id: data._id,
            type: data.type_mailing
        }
        console.log(postData)


        sweet.info('Essa ação fará com que todos os clientes que você já trabalhou possam voltar para os mailings que você criar no futuro. Clique em Confirmar para prosseguir', null, null, 'Confirmar')
            .then(async (e) => {
                if (e === true) {
                    const result = await api.post(`clear-model-worked`, postData, false)
                    if (result.error === true && result) {
                        msg.error('Erro ao limpar modelo', result.data)
                        return;
                    }

                    await this.getModelos(true)
                    msg.success('Sucesso', 'Modelo foi limpo com sucesso')
                    return;
                }
            })
    }

    async closeContain() {
        clearInterval(this.state.intervalId);
        await this.getModelos();
        const close = document.getElementById('close');
        close.classList.remove('open');
        close.style.width = "0px";
    }

    setMessagesScreenToFalse = () => {
        this.setState(
            {
                showMessageScreenTeamNotParam: false,
                showMessageScreenUploadDialerDone: false,
                showMessageScreenUploadDialerError: false,
                showMessageScreenUploadingDialer: false
            }
        )
    }

    render() {
        const {
            listModelsMailing,
            nameModal,
            subtitleModal,
            mailingsListData,
            typeModal,
            typeMailing,
            dataModal,
            dataIdModal,
            auth,
            showMessageScreenTeamNotParam, 
            showMessageScreenUploadingDialer,
            showMessageScreenUploadDialerDone,
            showMessageScreenUploadDialerError
        } = this.state

        return (
            <>
                <MessageScreen show={showMessageScreenTeamNotParam} onShowChange={this.setMessagesScreenToFalse} fileName='error_icon' heightImage={235} buttonColor="success" buttonText="Entendi" title='Sua equipe não está parametrizada' message='Para enviar para a discadora configure a discadora(ou peça para seu supervisor) na sua equipe, adicione os parâmetros em "Configurações > Equipe > Discadora(ícone do fone de ouvido)" ' /> 
                <MessageScreen show={showMessageScreenUploadingDialer} onShowChange={this.setMessagesScreenToFalse} fileName='robot_work_board' heightImage={290} showButton={false} showCloseButton={false} buttonColor="success" title='Enviando para a discadora...' message="Nosso robô está trabalhando incansavelmente enviando o mailing para a sua discadora" footerMessage="Assim que terminar, verifique a importação no painel da discadora" /> 
                <MessageScreen show={showMessageScreenUploadDialerDone} onShowChange={this.setMessagesScreenToFalse} fileName='done_icon' heightImage={260} showButton={true} buttonColor="success" buttonText="Ok, vou conferir" title='Tudo pronto! Envio Finalizado' message="Nosso robô conseguiu enviar os contatos para a sua discadora. A partir de agora, a importação dos contatos fica à cargo do sistema da DISCADORA" footerMessage="Vá até o painel da discadora e verifique o andamento da importação" /> 
                <MessageScreen show={showMessageScreenUploadDialerError} onShowChange={this.setMessagesScreenToFalse} fileName='error_server' heightImage={300} showButton={true} buttonColor="success" buttonText="Tudo bem" title='Que pena, ocorreu um problema! :(' message="Nosso robô não conseguiu enviar os contatos para a sua discadora. Houve algum problema na comunicação entre os sistemas. Verifique a sua integração e as configurações da sua equipe" footerMessage="Verifique os dados e tente novamente mais tarde" /> 
                
                <div className="page-body">
                    <div className="container-fluid">
                        <PageTitle title={'Listar Modelos'} auth={auth} />
                    </div>
                    <div className="container-fluid">
                        <div class="alert alert-danger" role="alert">
                            <h4 class="alert-heading">Alerta!</h4>
                            <p style={{ fontSize: '1em' }}>Caso o seu modelo contenha uma grande quantidade de filtros ou filtros muito específicos, é importante ter em mente que isso pode resultar em atrasos no processo de abastecimento dos mailings e pode resultar em um número reduzido de clientes encontrados.</p>
                        </div>
                        <span></span>
                        <div className="customizer-contain" id='close'>
                            <div className="tab-content" id="c-pills-tabContent">
                                <div className="customizer-header">
                                    <i onClick={(e) => { this.closeContain(e) }} className="icofont-close icon-close"></i>
                                    <h5>Mailings criados</h5>
                                    <div className="float-end">
                                        {console.log(mailingsListData)}
                                        <button
                                            title="Criar novo mailing atrelado à esse modelo"
                                            disabled={mailingsListData?.mailings?.length >= 3}
                                            className="btn btn-sm btn-success text-uppercase flex-flex-rigth"
                                            id={mailingsListData._id}
                                            data-type={mailingsListData?.type_mailing}
                                            onClick={(e) => { this.createMailing(e) }}
                                        >
                                            <i className="fa fa-plus-circle"></i>
                                            <span style={{ marginLeft: '6px' }}>
                                                Novo Mailing
                                            </span>
                                        </button>
                                    </div>
                                    <h6 className="m-0 p-0"><i class="fa fa-filter" aria-hidden="true"></i> Filtro modelo: {mailingsListData.name}</h6>
                                    <p className="m-0 p-0"><i className="fa fa-info-circle"></i> Esses são os mailings criados a partir do filtro que foi definido no modelo </p>
                                </div>
                                <div className="customizer-body custom-scrollbar">
                                    <div className="tab-pane fade active show" id="c-pills-home" role="tabpanel" aria-labelledby="c-pills-home-tab">
                                        {mailingsListData?.mailings?.length >= 3 && <div className="alert alert-secondary dark alert-dismissible fade show" role="alert">
                                            <h6><strong>Atenção!</strong> Esse modelo já possui 3 mailings criados.</h6>
                                            Para não impactar sua operação, é necessário deletar um mailing ativo para criar um novo
                                            <button className="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close" data-bs-original-title="" title=""></button>
                                        </div>}
                                        <Table>
                                            {mailingsListData?.mailings && mailingsListData?.mailings?.length > 0 ? (
                                                <Tr>
                                                    <Td classTd={"text-center"}>
                                                        ID
                                                    </Td>
                                                    <Td classTd={"text-center"}>
                                                        Status
                                                    </Td>
                                                    <Td classTd={"text-center"}>
                                                        Total
                                                    </Td>
                                                    <Td classTd={"text-center"}>
                                                        Ações
                                                    </Td>
                                                </Tr>
                                            ) : ''}
                                            {mailingsListData?.mailings && mailingsListData?.mailings?.length > 0 ? mailingsListData.mailings.map((mailing, index) => {
                                                let progressTotalTst = (mailing.total_abastecido / 15000) * 100
                                                return (
                                                    <Tr key={index}>
                                                        <Td classTd={"text-center"}>
                                                            {mailing._id}
                                                        </Td>
                                                        <Td classTd={"text-center"}>
                                                            <span id={mailing.status} data-status={'statusMailing'}>
                                                                {mailing.status == 'abastecendo' && (<>
                                                                    <SpinnerCircular size={30} thickness={100} speed={100} color="#36ad47" secondaryColor="rgba(0, 0, 0, 0.44)" />
                                                                    <p>Procurando Clientes</p>
                                                                </>)}
                                                                {mailing.status != 'progresso' && (<>
                                                                    <span className={`badge badge-${mailing.status === 'abastecido' ? 'success' : 'info'} fw-lighter text-uppercase`} style={{ fontSize: '14px' }}>{mailing.status}</span>

                                                                    {/* {mailing.uploaded_dialer && (
                                                                    <>
                                                                        <br />
                                                                        <span className={`badge fw-${mailing.uploaded_dialer == true ? 'ligth' : 'info'} text-uppercase`} style={{ fontSize: '10px', background: '#146C43' }}>
                                                                            <i className="fa fa-volume-control-phone"></i>
                                                                            {mailing.uploaded_dialer == true ? 'Enviado para Discadora' : 'Erro ao enviar'}
                                                                        </span>
                                                                    </>
                                                                )} */}

                                                                </>)}
                                                            </span>
                                                        </Td>
                                                        <Td classTd={"text-center"}>
                                                            <p><b>{mailing.total_abastecido}</b> / 15.000</p>
                                                            <div className="progress">
                                                                <div className="progress-bar bg-primary" role="progressbar" style={{ width: progressTotalTst + "%" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </Td>
                                                        <Td classTd={"text-center"}>
                                                            <button disabled={mailing.status == 'abastecendo'} data-type={'actionMailing'} data-id={mailing._id} id={JSON.stringify(mailing)} onClick={(e) => { this.handleShow(e) }} className="btn btn-sm btn-dark">Ações</button>
                                                        </Td>
                                                    </Tr>
                                                )
                                            })
                                                : <div className="text-center col-12">
                                                    <NotFoundRobot />

                                                    <h6 className="m-0 p-0 mt-4">Esse modelo não possui mailings criados</h6>
                                                    <div className="p-0 mb-4"> Crie um novo mailing e o abasteça para utilizá-lo </div>
                                                    <button
                                                        title="Criar novo mailing atrelado à esse modelo"
                                                        disabled={mailingsListData?.mailings?.length >= 3}
                                                        className="btn btn-sm btn-success text-uppercase flex-flex-rigth"
                                                        id={mailingsListData._id}
                                                        data-type={mailingsListData?.type_mailing}
                                                        onClick={(e) => { this.createMailing(e) }}>
                                                        <i className="fa fa-plus-circle fs-6"></i>
                                                        <span className="fs-6" style={{ marginLeft: '10px' }}>
                                                            Novo Mailing
                                                        </span>
                                                    </button>
                                                </div>
                                            }
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h5>Modelos</h5>
                                    </div>
                                    <Table>
                                        <Tr>
                                            <Td classTd={"text-center"}>
                                                <b>
                                                    Modelo
                                                </b>
                                            </Td>
                                            <Td classTd={"text-center"}>
                                                <b>Equipe</b>
                                            </Td>
                                            <Td classTd={"text-center"}>
                                                <b>
                                                    Nome
                                                </b>
                                            </Td>
                                            <Td classTd={"text-center"}>
                                                <b>
                                                    Total Trabalhado
                                                </b>
                                            </Td>

                                            <Td classTd={"text-center"}>
                                                <b>
                                                    Mailings
                                                </b>
                                            </Td>

                                            <Td classTd={"text-center"}>
                                                <b>
                                                    Ações
                                                </b>
                                            </Td>
                                        </Tr>
                                        {listModelsMailing && listModelsMailing.length > 0 ? listModelsMailing.map((modelMailing) => {
                                            return (
                                                <>
                                                    <Tr className={`remove-tr-${modelMailing._id} `}>
                                                        <Td classTd={"text-center"}>
                                                            <p style={{ fontSize: "0.8em", marginBottom: "3px", padding: "0px" }}>ID: {modelMailing._id}</p>
                                                            <div className="fs-6">
                                                                <BadgeConvenioMailing convenioMailing={modelMailing.type_mailing} />
                                                                <BadgeTypeMailing typeMailing={modelMailing.type} />
                                                            </div>
                                                        </Td>
                                                        <Td classTd={"text-center"}>
                                                            <p style={{ fontSize: "0.9775em", marginBottom: "3px", padding: "0px" }}><b>{modelMailing.team_name}</b></p>
                                                        </Td>
                                                        <Td classTd={"text-center"}>
                                                            <p>{modelMailing.name}</p>
                                                            <p style={{ fontSize: "0.775em" }}><b>{formatarDataHora(modelMailing.created_at)}</b></p>
                                                        </Td>
                                                        <Td classTd={"text-center"}>
                                                            <b>{modelMailing?.total_worked ? modelMailing.total_worked.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 0}</b>
                                                            <br />
                                                            <span style={{ fontSize: '0.775em' }}>Clientes Trabalhados</span>
                                                        </Td>
                                                        <Td classTd={"text-center"}>
                                                            <div className="btn-group">
                                                                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">
                                                                    Visualizar Mailings Criados
                                                                </Tooltip>
                                                                }>
                                                                    <button id={JSON.stringify(modelMailing)}
                                                                        onClick={(e) => { this.viewMailings(e) }}
                                                                        className="btn btn-sm btn-success"
                                                                        data-bs-original-title="" title=""><i className="fa fa-file-text-o"></i></button>
                                                                </OverlayTrigger>
                                                                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">
                                                                    Limpar clientes trabalhados
                                                                </Tooltip>
                                                                }>
                                                                    <button id={JSON.stringify(modelMailing)}
                                                                        onClick={(e) => { this.clearFilter(e) }}
                                                                        className="btn btn-sm btn-secondary"
                                                                        data-bs-original-title="" title="">
                                                                        <i className="fa fa-exclamation-circle"></i></button>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Td>
                                                        <Td classTd={"text-center"}>
                                                            <>
                                                                <div className="btn-group">
                                                                    {/* <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">
                                                                        Editar Modelo
                                                                    </Tooltip>
                                                                    }>
                                                                        <button className="btn btn-sm btn-primary" data-bs-original-title="" title=""><i className="fa fa-edit"></i></button>
                                                                    </OverlayTrigger> */}
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">
                                                                        Visualizar filtro do modelo                                                                    </Tooltip>
                                                                    }>
                                                                        <button data-type={'filterModel'}
                                                                            data-id={modelMailing?._id}
                                                                            data-type-mailing={modelMailing?.type_mailing}
                                                                            onClick={(e) => { this.handleShow(e) }}
                                                                            class="btn btn-sm btn-primary"
                                                                            data-bs-original-title=""
                                                                            title=""><i class="fa fa-filter"></i></button>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">
                                                                        Deletar Modelo                                                                  </Tooltip>
                                                                    }>
                                                                        <button id={[modelMailing._id, modelMailing.type_mailing]} onClick={(e) => { this.deleteModel(e) }} className="btn btn-sm btn-danger" data-bs-original-title="" title=""><i className="fa fa-trash"></i></button>
                                                                    </OverlayTrigger>
                                                                </div>
                                                                {/* <button data-mailing={JSON.stringify(result)} onClick={(e) => { this.handleShow(e) }} className="btn btn-xs btn-info">AÇÕES</button> */}
                                                            </>
                                                        </Td>
                                                    </Tr>
                                                </>
                                            )
                                        }) : <p className="mt-2" style={{ textAlign: 'center' }}>Você ainda não possui mailings criados</p>}
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    size={typeModal == 'actionMailing' || typeModal == 'selectCampaign' ? 'md' : 'lg'}
                    style={{ zIndex: '9998' }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {nameModal}
                            {subtitleModal
                                ? <h6 className="font-weight-normal text-muted fs-6 m-0"> {subtitleModal} </h6>
                                : ''}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {typeModal === 'actionMailing' && (
                            <>
                                <div className="card-body">
                                    <div style={{ width: '100%' }}>
                                        <div className="dataAbsMailing">
                                            {console.log('###############################', dataModal)}
                                            {dataModal?.status == 'criado' ? (<>
                                                <div style={{ width: '100%' }}
                                                    id={dataIdModal}
                                                    data-type={dataModal?.type}
                                                    onClick={(e) => { this.absMailing(e) }}
                                                    className="btn btn-primary mb-3">
                                                    <i className="fa fa-upload" style={{ marginRight: '10px' }}></i>
                                                    <span>ABASTECER MAILING</span>
                                                </div>
                                            </>
                                            ) : ''}
                                        </div>
                                        <div>
                                            {(dataModal?.status == 'abastecido' || dataModal?.status == 'Não Existem Mais Clientes Nesse Perfil') && (
                                                <>
                                                    <button id={dataIdModal} onClick={(e) => { this.verifyTeamDialerBeforeUploadDialer(e) }} className="btn btn-info col-12 mb-3" title="Enviar nomes para discadora">
                                                        <i className="fa fa-cloud-upload" style={{ marginRight: '10px' }}></i>
                                                        ENVIAR PARA DISCADORA
                                                    </button>

                                                    {dataModal?.uploaded_dialer ?
                                                        (
                                                            <button id={dataIdModal} onClick={(e) => { this.markAsSentToDialer(e, false) }} style={{ backgroundColor: '#FD9843', color: '#FFF' }} className="btn col-12 text-uppercase mb-3" title="Sinalizar que o mailing foi NÃO foi enviado para discadora">
                                                                <i className="fa fa-times" style={{ marginRight: '10px' }}></i>
                                                                Marcar como não enviado
                                                            </button>

                                                        ) :
                                                        (
                                                            <button id={dataIdModal} onClick={(e) => { this.markAsSentToDialer(e) }} style={{ backgroundColor: '#6EA8FE', color: '#FFF' }} className="btn col-12 text-uppercase mb-3" title="Sinalizar que o mailing foi enviado para discadora de maneira manual">
                                                                <i className="fa fa-volume-control-phone" style={{ marginRight: '10px' }}></i>
                                                                Marcar como enviado
                                                            </button>
                                                        )}

                                                    <button id={dataIdModal} onClick={(e) => { this.downloadExcel(e) }} className="btn btn-success col-12 mb-3" title="Fazer download de mailing em formato de tabela">
                                                        <i className="fa fa-file-excel-o" style={{ marginRight: '10px' }}></i>
                                                        DOWNLOAD EXCEL
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                        <div>
                                            {dataModal?.status != 'abastecendo' && (
                                                <>
                                                    <button
                                                        id={dataIdModal}
                                                        data-type={dataModal?.type}
                                                        onClick={(e) => { this.deleteMailing(e) }}
                                                        className="btn btn-danger col-12">
                                                        <i className="fa fa-trash" style={{ marginRight: '10px' }}></i>
                                                        DELETAR MAILING
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        {typeModal === 'filterModel' && (
                            <>
                                <FilterMailing idMailing={dataIdModal} typeMailing={typeMailing} />
                            </>
                        )
                        }
                        {typeModal === 'selectCampaign' && (
                            <>
                                {
                                    dataModal === null ? (
                                        <>
                                            <Skeleton height={50} count={8} borderRadius="0.5rem" />
                                        </>
                                    ) : 
                                    (
                                        <>
                                            {
                                                dataModal.error === true ? (
                                                    <div className="text-center">
                                                        <div className="d-flex align-items-center">
                                                            <AnimationLottie fileJsonName="robot_scan" height={320} />
                                                        </div>
                                                        <span className="text-danger fs-6 mt-2">
                                                            Ocorreu um erro ao recuperar as equipes no ambiente da sua discadora
                                                        </span>

                                                        <div className="mt-3">
                                                            <button className="btn btn-info" id={dataModal.idMailing} onClick={(e) => { this.verifyTeamDialerBeforeUploadDialer(e) }}> 
                                                                <FontAwesomeIcon icon={faArrowsRotate} />
                                                                <span className="ml-1"> Tentar novamente </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                    {
                                                        dataModal.data.map((campaign, index) => {
                                                            const divButtonClassName = index === 0 ? 'mt-0' : 'mt-3';
                                                            return (
                                                                <>
                                                                    <div className={divButtonClassName} key={index}>
                                                                        <button onClick={() => { this.uploadMailingToDialer(dataModal.idMailing, dataModal.dialer, campaign) } } className="col-12 btn btn-info" style={{height: '50px'}} >ID: {campaign?.id} - {campaign?.attributes?.name}</button>
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                    </>
                                                )
                                            }
                                        </>
                                    )
                                    
                                }
                            </>
                        )
                        }
                    </Modal.Body>
                </Modal>
            </>)
    }
}
