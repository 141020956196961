import React, { Component, Redirect } from "react";
import BgImage from "../assets/images/proconsig/background_30_2.jpg";
import BgImageNoWhite from "../assets/images/proconsig/background_30.jpg";
import LogoDefault from "../assets/images/logo/login.png";
import MessageDisplay from "./messages/messageDisplay";
import { startLoad, stopLoad } from "./messages/load";
import sessionClient from './helpers/session';
import { SendSocket, OnSocket } from './helpers/socket';
import UpdatePassword from "./auth/update-password";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whitelabelSettings: JSON.parse(localStorage.getItem('whiteLabelSettings')),
      loginUser: null,
      passwordUser: null,
      captcha: "",
      newComponent: '',
      key: null,
    };
    this.loginSubmit = this.loginSubmit.bind(this);
  }

  loginSubmit(e) {
    e.preventDefault();
    const msg = new MessageDisplay();
    const { loginUser, passwordUser } = this.state;
    startLoad();

    fetch(`${process.env.REACT_APP_HTTP}/login`, {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        loginUser,
        passwordUser,
      }),
    })
      .then((res) => res.json())
      .then(async (res) => {
        if (res.error) {
          stopLoad();
          return msg.error("Login", res.data);
        }

        if (res.user.first_login === 'true') {
          msg.success("Sucesso", "Este eo seu primeiro login");
          this.setState({
            newComponent: <UpdatePassword id={res.user._id} />
          })
          stopLoad();
        } else {
          // acesse_token
          window.localStorage.setItem("token", res.data);
          window.localStorage.setItem("login", loginUser);
          window.localStorage.setItem("tokenHash", res.tokenHash);
          window.localStorage.setItem("loggeIn", true);
          window.localStorage.setItem("token_login", JSON.stringify(res.user.token_login));

          const socket = await sessionClient.getSession("socket");
          const session = await sessionClient.getSession(res.tokenHash);

          if (!session) {
            await sessionClient.newSession(res.tokenHash);
            await sessionClient.addInfoSession(res.tokenHash, {
              sendSocket: new SendSocket(socket.server, res.tokenHash),
              onSocket: new OnSocket(socket.server, res.tokenHash)
            });
            const session = await sessionClient.getSession(res.tokenHash);
            if (session) {
              session.sendSocket.sendLogin([{ login: loginUser, passwordUser: passwordUser, tokenHash: res.tokenHash }]);
            }
          }

          msg.success("Sucesso", "Login efetudo redirecionando para o painel");
          window.location.href = "./";
        }

      })
      .catch((e) => {
        stopLoad();
        msg.error("Server", "Not connect");
      });
  }

  componentDidMount() {
    document.querySelectorAll(".show-hide").forEach((el) => {
      el.style.display = "block";
    });

    document.querySelectorAll(".show-hide span").forEach((el) => {
      el.className = "show";
    });
  }

  showPassWord(el) {
    console.log(el);
    const input = document.querySelector('input[name="login[password]"');
    if (el.classList?.contains("show")) {
      input.setAttribute("type", "text");
      el.classList.remove("show");
    } else {
      input.setAttribute("type", "password");
      el.classList.add("show");
    }
  }

  render() {
    const { newComponent, whitelabelSettings } = this.state
    const backGround = whitelabelSettings?.domain == 'painel.proconsig.app' ? BgImage : null;
    const className = whitelabelSettings?.domain !== 'painel.proconsig.app' ? 'login-card bg-img-cover bg-center' : 'login-card';
    return (
      <>
        <Helmet>
          {/* <link rel="icon" type="image/png" href={whitelabelSettings?.logo ? whitelabelSettings?.logo : null} /> */}
          <title>{whitelabelSettings?.name ? whitelabelSettings?.name : 'RVXTECH'} - Painel de Login</title>
        </Helmet>
        {newComponent ? newComponent : (
          <>
            <div >
              <div className="container-fluid p-0" >
                <div className="row m-0">
                  <div className="col-12 p-0">
                    <div className={className} style={backGround ? {
                      background: `url(${backGround}) no-repeat center center fixed`,
                      backgroundSize: 'cover'
                    } : null}>
                      <div>

                        <div className="login-main" style={{ backgroundColor: '#fff', opacity: '0.9' }}>
                          <div>
                            <div className="logo" style={{ width: '100%' }}>
                              <img
                                className="img-fluid for-light"

                                src={whitelabelSettings?.logo ? whitelabelSettings.logo : LogoDefault}
                                style={{ maxHeight: '120px' }}
                                alt="looginpage"
                              />
                              <img
                                className="img-fluid for-dark"
                                src={whitelabelSettings?.logo ? whitelabelSettings.logo : LogoDefault}
                                alt="looginpage"
                              />
                            </div>
                          </div>
                          <form className="theme-form" onSubmit={this.loginSubmit}>
                            <div className="form-group">
                              <label className="col-form-label">Login</label>
                              <input style={{ opacity: '0 !important' }}
                                className="form-control"
                                type="text"
                                required=""
                                placeholder="Informe seu usuário"
                                onChange={(e) =>
                                  this.setState({ loginUser: e.target.value })
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label className="col-form-label">Senha</label>
                              <div className="form-input position-relative">
                                <input
                                  style={{ opacity: '0 !important' }}
                                  className="form-control"
                                  type="password"
                                  name="login[password]"
                                  required=""
                                  placeholder="*********"
                                  onChange={(e) =>
                                    this.setState({ passwordUser: e.target.value })
                                  }
                                />
                                <div className="show-hide">
                                  <span
                                    onClick={(e) => this.showPassWord(e.target)}
                                  ></span>
                                </div>
                              </div>
                            </div>
                            <div className="form-group mb-0">
                              <div className="checkbox p-0">
                                <input id="checkbox1" type="checkbox" />
                              </div>
                              {/* <a
                                className="link"
                                href="forget-password"
                                style={{ fontSize: "0.775em" }}
                              >
                                Recuperar Senha?
                              </a> */}
                              <br />
                              <div className="text-end mt-2">
                                <button
                                  className="btn btn-primary btn-block w-100"
                                  type="submit"
                                >
                                  Logar
                                </button>
                              </div>
                            </div>
                            <h6 className="text-muted mt-3 text-center mt-4">{whitelabelSettings?.name ? whitelabelSettings?.name : 'RVXTech'}</h6>
                            {/* <p className="mt-2 mb-0 text-center">
                            Não possui conta?
                            <a
                              className="ms-2"
                              href="https://forms.gle/ruXVCa5ceEs5LPpeA"
                              target="_blak"
                            >
                              Cadastre-se aqui
                            </a>
                          </p> */}
                          </form>
                        </div>
                      </div>
                    </div >
                  </div >
                </div >
              </div >
            </div>
          </>
          // <div className="container-fluid">
          //   <div className="row">
          //     <div className="col-xl-7 order-1">
          //       <img
          //         className="bg-img-cover bg-center"
          //         src={BgImage}
          //         alt="looginpage"
          //       />
          //     </div>
          //     <div className="col-xl-5 p-0">
          //       <div className="login-card">
          //         <div>

          //           <div className="login-main">
          //             <div style={{ textAlign: 'center' }}>
          //               <a className="logo text-start" href="index.html">
          //                 <img
          //                   className="img-fluid for-light"
          //                   src={whitelabelSettings?.logo ? whitelabelSettings.logo : LogoDefault}
          //                   style={{ width: "35%" }}
          //                   alt="looginpage"
          //                 />
          //                 <img
          //                   className="img-fluid for-dark"
          //                   src={LogoDefault}
          //                   alt="looginpage"
          //                 />
          //               </a>
          //             </div>
          //             <form className="theme-form" onSubmit={this.loginSubmit}>
          //               <div className="form-group">
          //                 <label className="col-form-label">Login</label>
          //                 <input
          //                   className="form-control"
          //                   type="text"
          //                   required=""
          //                   placeholder="login"
          //                   onChange={(e) => 
          //                     this.setState({loginUser: e.target.value })
          //                   }
          //                 />
          //               </div>
          //               <div className="form-group">
          //                 <label className="col-form-label">Senha</label>
          //                 <div className="form-input position-relative">
          //                   <input
          //                     className="form-control"
          //                     type="password"
          //                     name="login[password]"
          //                     required=""
          //                     placeholder="*********"
          //                     onChange={(e) => 
          //                       this.setState({passwordUser: e.target.value })
          //                     }
          //                   />
          //                   <div className="show-hide">
          //                     <span
          //                       onClick={(e) => this.showPassWord(e.target)}
          //                     ></span>
          //                   </div>
          //                 </div>
          //               </div>
          //               <div className="form-group mb-0">
          //                 <div className="checkbox p-0">
          //                   <input id="checkbox1" type="checkbox" />
          //                 </div>
          //                 <a
          //                   className="link"
          //                   href="forget-password"
          //                   style={{ fontSize: "0.775em" }}
          //                 >
          //                   Recuperar Senha?
          //                 </a>
          //                 <br />
          //                 <div className="text-end mt-2">
          //                   <button
          //                     className="btn btn-primary btn-block w-100"
          //                     type="submit"
          //                   >
          //                     Logar
          //                   </button>
          //                 </div>
          //               </div>
          //               <h6 className="text-muted mt-3 or">{whitelabelSettings?.name ? whitelabelSettings?.name : 'RVXTech'}</h6>

          //               <p className="mt-2 mb-0 text-center">
          //                 Não possui conta?
          //                 <a
          //                   className="ms-2"
          //                   href="https://forms.gle/ruXVCa5ceEs5LPpeA"
          //                   target="_blak"
          //                 >
          //                   {" "}
          //                   Cadastre-se aqui
          //                 </a>
          //               </p>
          //             </form>
          //           </div>
          //         </div>
          //       </div >
          //     </div>
          //   </div>
          // </div>
        )
        }
      </>
    );
  }
}
