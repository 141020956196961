import { Input, InputData, SelectForm, InputCurrency } from "../../../../helpers/formData"

const DadosClientSeguroVida = () => {
    return (
        <>
            <Input
                classInput={"col-sm-4 col-md-4 col-xl-4"}
                name={'idade_de'}
                label={'Idade de'}
                placeholder={"Idade de"}
            />
            <Input
                classInput={"col-sm-6 col-md-4 col-xl-4"}
                name={'idade_ate'}
                label={'Idade até'}
                placeholder={"Idade até"}
            />

            <SelectForm
                classInput={"col-sm-6 col-md-4 col-xl-4"}
                name={"formas_recebimento"}
                optionLebel="Forma de Recebimento:"
                labelName={'Forma de Recebimento'}
                data={[
                    {
                        label: 'Ambos',
                        value: 'ambos'
                    },
                    {
                        label: 'Conta Corrente',
                        value: 'CONTA CORRENTE'
                    },
                    {
                        label: 'Cartão Magnético',
                        value: 'CARTÃO MAGNETICO'
                    }
                ]}>
            </SelectForm>

        </>
    )
}

export default DadosClientSeguroVida