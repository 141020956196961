import React, { useState } from "react";
import NoAvatar from "../../../../assets/images/proconsig/no_avatar.jpg";
import { OverlayTrigger, Tooltip, Modal, Button } from "react-bootstrap";
import NewAgendaModalFicha from "../NewAgendaModalFicha";
import MessageDisplay from "../../../messages/messageDisplay";
import { ApiRequest } from "../../../helpers/Api";
import { startLoad, stopLoad } from "../../../messages/load";
import { formatDate } from "../../../helpers/FuncHelpers";
import { BtnTooltip } from "../../../helpers/ButtonTooltip";
import SimuladorInss from "../data_inss/simulador_margem";

const DataClient = (props) => {
  const { client, type, atualizacaoBeneficios, beneficios, obterTaxa, emprestimos, auth } = props;
  const [showModal, setShowModal] = useState(false);

  const validadorBtn = () => {
    const dateHoje = new Date();
    if (!beneficios?.[0]?.update_off) return true;
    const updateOff = new Date(beneficios[0].update_off);
    updateOff.setDate(updateOff.getDate() + 15);
    return dateHoje > updateOff;
  }

  const simularContratos = () => {
    const close = document.getElementById('close_simulator');
    close.classList.add('open');
    close.style.width = "93%";
  }

  const closeContain = () => {
    const close = document.getElementById('close_simulator');
    close.classList.remove('open');
    close.style.width = "0px";
  }


  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const createAgenda = async (agendaData) => {
    const msg = new MessageDisplay();
    try {
      startLoad();
      const api = new ApiRequest();

      // Realizar a requisição POST para criar uma nova agenda
      const result = await api.post("schedule-create", agendaData, false);

      stopLoad();

      if (result.error === true && result.data) {
        // Lidar com o erro na criação da agenda
        msg.error("Erro ao criar agenda", result.data);
      } else {
        // Agenda criada com sucesso
        msg.success("Agenda criada com sucesso!");
      }
    } catch (error) {
      msg.error("Erro ao criar agenda");
    }
  };



  return (
    <>

      <div className="customizer-contain" id='close_simulator' style={{ overflow: 'auto', height: '90%' }}>
        <SimuladorInss closeContain={closeContain} beneficios={beneficios} emprestimos={emprestimos} />
      </div>



      <div className="card">
        <div className="row">
          <div className="col-md-12">
            <div
              className="d-flex justify-content-between align-items-center"
              style={{
                padding: "12px 15px 15px 0px",
                borderRadius: "5px",
              }}
            >
              <div>
                <div className="d-flex align-items-center">
                  <div style={{ width: "15%" }}>
                    <img
                      style={{
                        width: "50%",
                        borderRadius: "50%",
                      }}
                      src={NoAvatar}
                      alt=""
                    />
                  </div>
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ fontSize: "0.9em", marginBottom: '0px' }}>{client.nome} <span className={`btn btn-xs btn-${client.sexo == 'FEMININO' ? 'secondary' : 'primary'}`}>{client.sexo}</span></h5>
                    <p style={{ margin: '0px' }}>CPF: {client.doc}</p>
                    <p style={{ margin: '0px', padding: '0px' }}>RG: {client.rg_numero}</p>

                    <p style={{ margin: '0px', padding: '0px' }}>Nascimento: {formatDate(client.dataNascimento)}</p>
                  </div>
                </div>
              </div>

              <div>
                <div className="d-flex align-items-center">
                  {type === "inss" ? (
                    <>

                      {validadorBtn() ? (<>

                        <BtnTooltip
                          dataClient={JSON.stringify(client)}
                          dataType="offline"
                          name={'Offline'}
                          desc={'Busca a atualização offline mais recente para essesbenefícios'}
                          onClick={(e) => atualizacaoBeneficios(e)}
                          color={'info'}
                          icon={'fa fa-spin fa-circle-o-notch'}
                        />
                      </>) : (
                        <>
                          {auth.level == 'operador' && (<>
                            <BtnTooltip
                              name={'Simulador'}
                              onClick={(e) => simularContratos(e)}
                              desc={'Utilizado para simulação de Emprestimos'}
                              color={'primary'}
                              icon={'fa fa-rocket'}
                            />
                          </>)}
                          <BtnTooltip
                            name={'Taxa'}
                            desc={'Utilize a *inteligência Artificial* para calcular a taxa real do contrato do cliente'}
                            color={'danger'}
                            id={beneficios[0].doc}
                            icon={'fa fa-money'}
                            onClick={(e) => obterTaxa(e)}
                          />

                          <BtnTooltip
                            name={formatDate(beneficios[0].update_off)}
                            desc={`Última atualização feita foi em *${formatDate(beneficios[0].update_off)}* trazendo a melhor atualização disponível no Mercado`}
                            color={'success'}
                            id={beneficios[0].doc}
                            icon={'fa fa-calendar'}
                            onClick={(e) => obterTaxa(e)}
                          />


                        </>)}
                    </>
                  ) : (
                    <>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip">
                            Clique aqui para agendar este cliente
                          </Tooltip>
                        }
                      >
                        <button
                          onClick={handleModalOpen}
                          className="btn btn-sm btn-info"
                          style={{ marginRight: "5px" }}
                        >
                          Agendar
                        </button>
                      </OverlayTrigger>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >

      {/* Modal */}
      < Modal show={showModal} onHide={handleModalClose} >
        <NewAgendaModalFicha
          createAgenda={createAgenda}
          handleClose={handleModalClose}
          client={client}
        />
      </Modal >
    </>
  );
};

export default DataClient;
