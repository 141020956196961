import { Component } from "react";
import { Table, Tr, Td } from "../helpers/Table";
import MessageDisplay from "../messages/messageDisplay";
import { startLoad, stopLoad } from "../messages/load";
import sweetAlert from "./sweet";
import EditUser from "../painel/users/edit";
import Modal from 'react-bootstrap/Modal';
import { Form, Input, InputFile, BtnForm, SelectForm } from "./formData";
import { ApiRequest } from "./Api";
import Select from 'react-select'

export default class UsersList extends Component {
    constructor(props) {
        super(props)
        console.log(props)
        this.state = {
            auth: JSON.parse(localStorage.getItem('user')),
            newComponent: '',
            data: [],
            show: false,
            user: null,
            teams: [],
            selectTeam: false,
            selectMultipleTeam: false,
            teamSelected: [],
            loadPage: false,
            typeUser: false,
            teamsView: false,
        }
        this.handleShow = this.handleShow.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.resetPasswordUser = this.resetPasswordUser.bind(this);
        this.changeUser = this.changeUser.bind(this);
        this.setUpdateStatusUser = this.setUpdateStatusUser.bind(this);
        this.getTeams = this.getTeams.bind(this)

    }


    componentDidMount() {
        this.setState({
            data: this.props.data
        })
    }

    componentDidUpdate(prevProps, prevState) {
        console.log('aquiiii')
        if (this.props.data !== prevProps.data) {
            console.log('previprops', this.props.data)
            this.setState({
                data: this.props.data
            })
        }
    }

    copyToClipboard(text) {
        navigator.clipboard.writeText(text);
        const msg = new MessageDisplay();
        return msg.success("User", 'A senha foi copiada para a área de transferencia');
        // alert(`Copied "${text}" to clipboard!`);
    }

    async submitForm(e) {

        const { logo, favicon } = this.state
        const files = {
            thumb: logo,
        }

        const postData = {
            teams: this.state.teams
        }

        const api = new ApiRequest();
        const msg = new MessageDisplay();
        const result = await api.post(`user-edit`, e, true, postData)

        if (result.error) {
            return msg.error("Usuário", result.data);
        }

        this.setState({
            show: false,
            data: this.state.data.map((item) => (item._id === result.data._id ? result.data : item))
        })

        return msg.success("Usuário", 'Usuário atualizado com sucesso');

    }

    handleImageChange(event) {
        const value = event.target.name
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const base64 = reader.result;
                if (value == 'thumb') {
                    this.setState({ thumb: base64 });
                }
            };
            reader.readAsDataURL(file);
        } else {
            this.setState({ thumb: null });
        }
    };

    handleClose() {
        this.setState({
            show: false
        })
    }

    handleSelectChange = (selectedOptions) => {
        console.log(selectedOptions)
        let teamSelectedCopy = [...this.state.teamSelected];
        teamSelectedCopy = selectedOptions;
        this.setState(
            {
                teamSelected: teamSelectedCopy,
                teams: teamSelectedCopy.map(option => option.value)
            });
    };

    async handleShow(e) {
        const { auth } = this.state
        const value = e.target.closest('.btn')
        const user = JSON.parse(value.id)
        const typeUser = value.getAttribute('data-level')

        if (auth.level === 'empresa' || auth.level === 'supervisor') {
            const teams = await this.getTeams();
            const teamsList = teams.map((t) => {
                return ({
                    value: t._id,
                    label: t.name
                })
            })

            this.setState({
                teamsView: true,
                teamsList: teamsList
            })

            if (user.level == 'operador') {
                this.setState({
                    selectMultipleTeam: false,
                    selectTeam: true
                })
            }

            if (user.level == 'supervisor') {
                const teamsMap = user.teams.map(({ team_id: value, name: label }) => ({ value, label }));
                this.setState({
                    selectTeam: false,
                    selectMultipleTeam: true,
                    teamSelected: teamsMap,

                })
            }
        }

        this.setState({
            show: true,
            user: user,
            typeUser: typeUser
        })
    }

    resetPasswordUser(e) {

        const msg = new MessageDisplay();
        const tokenLocalStorage = window.localStorage.getItem("token");
        const tokenHash = window.localStorage.getItem("tokenHash");
        const login = window.localStorage.getItem("login");
        const sweet = new sweetAlert();

        sweet.info('Você deseja resetar a senha desse usuário', null, null, 'Confirmar').then((status) => {
            if (status === true) {
                startLoad();
                fetch(`${process.env.REACT_APP_HTTP}/users-change-password/${e._id}/${login}/${tokenHash}`, {
                    method: "GET",
                    crossDomain: true,
                    headers: { "Content-Type": "application/json", "Accept": "application/json", authorization: `Bearer ${tokenLocalStorage}` }
                })
                    .then((res) => res.json())
                    .then(async (res) => {

                        if (res.error) {
                            stopLoad();
                            return msg.error("User", res.data);
                        }
                        stopLoad();
                        sweet.info(`Sua nova senha foi gerado com sucesso: <br><br>login: <b>${res.data.login}</b><br><br>senha: <b> <a href="#" onClick="${this.copyToClipboard(res.password)}">${res.password}</a></b> <br><br>ela precisará ser trocada no próximo login`)

                    })
                    .catch(() => {
                        stopLoad();
                        msg.error("Server", "Not connect");
                    });
            }
        })

    }

    async getTeams() {
        const api = new ApiRequest();
        const result = await api.get(`teams-get-all`)
        const msg = new MessageDisplay();

        if (result.error) {
            return msg.error("Usuários", "Acesso Negado");
        }

        return result.data
    }

    async changeUser(e) {
        let msgInfo = 'Destaviar Usuário';
        if (e.status === 'inactive') {
            msgInfo = 'Ativar Usuário'
        }
        const api = new ApiRequest();
        const msg = new MessageDisplay();
        const sweet = new sweetAlert();

        sweet.info(`Você deseja realmente ${msgInfo} esse usuário`, null, null, 'Confirmar').then(async (res) => {
            if (res === true) {
                const result = await api.get(`users-change-status/${e._id}`)
                if (result.error) {
                    stopLoad();
                    return msg.error("User", result.data);
                }
                console.log(result)
                stopLoad();
                await this.setUpdateStatusUser(result.data)
                return msg.success("User", 'Usuário atualizado');
            }
        })
    }

    async setUpdateStatusUser(user) {
        console.log(user)
        const idUser = document.getElementsByClassName(`findStatus${user._id}`)
        const icon = idUser[0].children[0]
        if (user.status == 'active') {
            idUser[0].className = `findStatus${user._id} btn btn-danger`
            idUser[0].title = 'Desativar Usuário'
            icon.className = 'fa fa fa-close'
        } else {
            idUser[0].className = `findStatus${user._id} btn btn-success`
            idUser[0].title = 'Ativar Usuário'
            icon.className = 'fa fa fa-check'
        }
    }

    render() {

        const { newComponent, auth, user, selectTeam, teamsView, teamsList, selectMultipleTeam, teamSelected, data } = this.state;
        return (
            <>
                {newComponent ? newComponent : (
                    <>
                        {data && data.length > 0 ? (
                            <>
                                <Table>
                                    <Tr >
                                        <Td classTd={"text-center"}>
                                            <p>Nome</p>
                                        </Td>
                                        <Td classTd={"text-center"}>
                                            <p>Login</p>
                                        </Td>
                                        <Td classTd={"text-center"}>
                                            <p>Último Acesso</p>
                                        </Td>
                                        <Td classTd={"text-center"}>
                                            <p>Perfil</p>
                                        </Td>
                                        <Td classTd={"text-center"}>
                                            <p>Status</p>
                                        </Td>
                                        {auth.level == 'empresa' || auth.level == 'supervisor' ? (
                                            <Td classTd={"text-center"}>
                                                <p>Equipe</p>
                                            </Td>
                                        ) : ''}
                                        <Td classTd={"text-center"}>
                                            <p>Ações</p>

                                        </Td>
                                    </Tr>
                                    <>
                                        {data.map(result => {
                                            return (
                                                <Tr key={result._id}>
                                                    <Td classTd={"text-center"}>
                                                        <p>{result.name}</p>
                                                    </Td>
                                                    <Td classTd={"text-center"}>
                                                        <p>{result.login}</p>
                                                    </Td>
                                                    <Td classTd={"text-center"}>
                                                        <p>{result.updated_at}</p>
                                                    </Td>
                                                    <Td classTd={"text-center"}>
                                                        <p>{result.level}</p>
                                                    </Td>
                                                    <Td classTd={"text-center"}>
                                                        <p>{result.status}</p>
                                                    </Td>
                                                    {auth.level == 'empresa' || auth.level == 'supervisor' ? (
                                                        <Td classTd={"text-center"}>
                                                            {result.teams.map ? result.teams.map((con, index) => {
                                                                return (
                                                                    <>
                                                                        {con.name}
                                                                        {index !== con.name.length - 1 && ","}
                                                                    </>
                                                                )
                                                            }) : <p>Nenhuma Equipe</p>}
                                                        </Td>
                                                    ) : ''}
                                                    <Td classTd={"text-center"}>
                                                        <div className="btn-group">
                                                            <button data-level={auth.level !== 'empresa' ? true : false} id={JSON.stringify(result)} onClick={(e) => { this.handleShow(e) }} className="btn btn-secondary" data-bs-original-title="" title="Editar Usuário"><i className="fa fa fa-edit"></i></button>
                                                            <button onClick={() => this.resetPasswordUser(result)} className="btn btn-primary" data-bs-original-title="" title="Resetar Senha">
                                                                <i className="fa fa-key"></i>
                                                            </button>

                                                            <button id={result._id}
                                                                className={`findStatus${result._id} btn btn-${result.status === 'active' ? 'danger' : 'success'}`}
                                                                data-bs-original-title=""
                                                                title={`${result.status === 'active' ? 'Desativar Usuário' : 'Ativar Usuário'}`}
                                                                onClick={() => this.changeUser(result)}
                                                            >
                                                                <i id='filho' className={`fa fa fa-${result.status === 'active' ? 'close' : 'check'} `}></i>
                                                            </button>
                                                        </div>
                                                    </Td>
                                                </Tr>
                                            )
                                        })}
                                    </>
                                </Table>
                            </>
                        ) : <p className="text-center mt-3">Você ainda não possui usuários cadastrados</p>}
                    </>
                )}

                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    size={'xl'}
                    style={{ zIndex: '9998' }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Editar Usuário
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={(e) => this.submitForm(e)}>
                            <Input
                                type={'hidden'}
                                name={'_id'}
                                valueInput={user?._id}
                            />
                            <Input
                                classInput={"col-sm-6 col-md-6 col-xl-4"}
                                label={'Nome do Usuário'}
                                type={'text'}
                                name={'name'}
                                valueInput={user?.name}
                                placeholder={"Nome do Usuário"}
                            />
                            <Input
                                classInput={"col-sm-6 col-md-6 col-xl-4"}
                                label={'E-mail'}
                                type={'email'}
                                name={'email'}
                                valueInput={user?.email}
                                placeholder={"E-mail"}
                            />
                            <Input
                                classInput={"col-sm-6 col-md-6 col-xl-4"}
                                label={'Login'}
                                type={'mail'}
                                name={'login'}
                                valueInput={user?.login}
                                placeholder={"Login"}
                            />
                            <div className="col-sm-6 col-md-6 col-xl-4" style={{ display: 'none' }}>
                                <label className="form-label">Level</label>
                                <select className="form-select" name={'level'}>
                                    <option selected value={user?.level}> {user?.level} </option>
                                </select>
                            </div>
                            {teamsView ? (<>
                                {selectTeam ? (
                                    <SelectForm
                                        classInput={"col-sm-6 col-md-6 col-xl-12"}
                                        name={"teamsUser"}
                                        valueSelect={user.teams[0] ? user.teams[0].team_id : ''}
                                        level={auth.level}
                                        labelName={'Equipes'}
                                        data={teamsList}>
                                    </SelectForm>

                                ) :
                                    ''}
                                {selectMultipleTeam ? (
                                    <>
                                        <div className="col-sm-6 col-md-6 col-xl-12">
                                            <label className="form-label">Equipes</label>
                                            <Select
                                                formatGroupLabel={'Equipes'}
                                                isMulti
                                                name="teams"
                                                options={teamsList}
                                                value={teamSelected.filter(option => {
                                                    return teamsList.some(obj => obj.value === option.value);
                                                })}
                                                onChange={this.handleSelectChange}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                            />
                                        </div>
                                    </>
                                ) :
                                    ''}
                            </>) : ''}

                            <BtnForm name={'Atualizar Usuário'} />
                        </Form>
                    </Modal.Body>
                </Modal>
            </>)
    }
}