import { Component } from "react";
import PageTitle from "../../helpers/PageTitle";
import { Form, Input, SelectForm, BtnForm, InputFile } from "../../helpers/formData";
import MessageDisplay from "../../messages/messageDisplay";
import { startLoad, stopLoad } from "../../messages/load";
import ListUser from "./list";
import ReturnPage from "../../helpers/ReturnPage";
import Select from 'react-select'


export default class EditUser extends Component {
    constructor(props) {
        super(props)
        const auth = localStorage.getItem('user')
        this.state = {
            auth: JSON.parse(auth),
            _id: props.id._id,
            company_id: props.id.company_id,
            name: props.id.name,
            email: props.id.email,
            login: props.id.login,
            password: '',
            team: (props.id.teams ? props.id.teams : ''),
            level: props.id.level,
            thumb: '',
            teamsList: '',
            status: props.id.status,
            newComponent: '',
            teams: [],
            selectTeam: false,
            selectMultipleTeam: false,
            teamSelected: [],
            loadPage: false
        }

        this.getTeams = this.getTeams.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this);

    }

    handleSelectChange = (selectedOptions) => {
        console.log(selectedOptions)
        let teamSelectedCopy = [...this.state.teamSelected];
        teamSelectedCopy = selectedOptions;
        this.setState(
            {
                teamSelected: teamSelectedCopy,
                teams: teamSelectedCopy.map(option => option.value)
            });
    };

    componentDidMount() {
        this.getTeams()

        const { level, team, teamsList } = this.state

        const teamsMap = team.map(({ team_id: value, name: label }) => ({ value, label }));
        this.setState({
            selectMultipleTeam: false,
            selectTeam: false
        })

        if (level == 'operador') {
            this.setState({
                selectTeam: true
            })
        }

        if (level == 'supervisor') {
            this.setState({
                selectMultipleTeam: true,
                teamSelected: teamsMap,

            })
        }
    }
   

    submitForm(e) {
        const { id } = this.props
        console.log(id)
        e.preventDefault();
        const msg = new MessageDisplay();

        const form = e.target;

        const formData = new FormData(form);
        const serializedForm = Object.fromEntries(formData.entries());
        serializedForm.teams = this.state.teams
        serializedForm.thumb = this.state.thumb
        serializedForm._id = id._id


        const tokenLocalStorage = localStorage.getItem("token");
        const tokenHash = localStorage.getItem("tokenHash");
        const login = localStorage.getItem("login");

        startLoad();
        fetch(`${process.env.REACT_APP_HTTP}/user-edit/${login}/${tokenHash}`, {
            method: 'PUT',
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                authorization: `Bearer ${tokenLocalStorage}`
            },
            body: JSON.stringify({
                ...serializedForm
            })
        })
            .then((res) => res.json())
            .then(async (res) => {

                if (res.error) {
                    stopLoad();
                    return msg.error("Usuário", res.data);
                }

                stopLoad();
                this.setState({
                    newComponent: <ListUser />
                })
                return msg.success("Usuário", 'Usuário atualizado com sucesso');


            })
            .catch((erro) => {
                console.log(erro)
                stopLoad();
                msg.error("Server", "Not connect");
            });

        e.preventDefault();
    }

    handleImageChange(event) {
        console.log(event.target.files)

        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = () => {
                const base64 = reader.result;
                this.setState({ thumb: base64 });
                // this.props.onChange(base64);
            };

            reader.readAsDataURL(file);
        } else {
            this.setState({ thumb: null });
            // this.props.onChange(null);
        }

        // this.setState({ logo: event.target.files })
    };

    returPage = () => {
        this.setState({
            newComponent: <ListUser />
        })
    };

    render() {
        const { newComponent, auth, name, email, level, selectTeam, selectMultipleTeam, teamsList, teamSelected, team, loadPage } = this.state
        return (
            <>
                {loadPage ? (

                    newComponent ? newComponent : (
                        <div className="page-body">
                            <div className="container-fluid">
                                <PageTitle title={"Editar Usuário"} nameCompany={"Proconsig"} />
                            </div>
                            <div className="container-fluid">
                                <ReturnPage OnClick={this.returPage} />
                                <div className="edit-profile">
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <Form title={'Criar Usuário'} onSubmit={(e) => this.submitForm(e)}>
                                                <Input
                                                    classInput={"col-sm-6 col-md-6 col-xl-4"}
                                                    label={'Nome do Usuário'}
                                                    type={'text'}
                                                    name={'name'}
                                                    valueInput={name}
                                                    placeholder={"Nome do Usuário"}
                                                />
                                                <Input
                                                    classInput={"col-sm-6 col-md-6 col-xl-4"}
                                                    label={'E-mail'}
                                                    type={'mail'}
                                                    name={'email'}
                                                    valueInput={email}
                                                    placeholder={"E-mail"}
                                                />
                                                <div className="col-sm-6 col-md-6 col-xl-4">
                                                    <label className="form-label">Level</label>
                                                    <select className="form-select" name={'level'}>
                                                        <option selected value={level}> {level} </option>
                                                    </select>
                                                </div>
                                                {selectTeam ? (
                                                    <SelectForm
                                                        classInput={"col-sm-6 col-md-6 col-xl-12"}
                                                        name={"teamsUser"}
                                                        valueSelect={team[0] ? team[0].team_id : ''}
                                                        level={auth.level}
                                                        labelName={'Equipes'}
                                                        data={teamsList}>
                                                    </SelectForm>

                                                ) :
                                                    ''}
                                                {selectMultipleTeam && loadPage ? (
                                                    <>
                                                        <div className="col-sm-6 col-md-6 col-xl-12">
                                                            <label className="form-label">Equipes</label>
                                                            <Select
                                                                formatGroupLabel={'Equipes'}
                                                                isMulti
                                                                name="teams"
                                                                options={teamsList}
                                                                value={teamSelected.filter(option => {
                                                                    return teamsList.some(obj => obj.value === option.value);
                                                                })}
                                                                onChange={this.handleSelectChange}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                            />
                                                        </div>
                                                    </>
                                                ) :
                                                    ''}
                                                <BtnForm name={'Atualizar Usuário'} />
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )

                ) : ''}
            </>
        )
    }
}