import { Component } from "react";
import Swal from "sweetalert2";

export default class sweetAlert extends Component {
  constructor(props) {
    super(props);
    this.messageApi = Swal;

  }

  success = async (text, timer = null, timerProgressBar = null, nameButton = null) => {
    return this.messageApi.fire({
      title: "Sucesso",
      html: text,
      icon: "success",
      confirmButtonText: nameButton != null ? nameButton : 'Ok',
      timer: timer,
      timerProgressBar: timerProgressBar,
      appendToBody: true,
      heightAuto: false
    }).then((result) => {
      if (result.isConfirmed) {
        return true
      }
    });
  };

  error = async (text, timer = null, timerProgressBar = null, nameButton = null) => {
    return this.messageApi.fire({
      title: "Erro",
      html: text,
      icon: "error",
      confirmButtonText: nameButton != null ? nameButton : 'Ok',
      timer: timer,
      timerProgressBar: timerProgressBar,
      appendToBody: true,
      heightAuto: false
    }).then((result) => {
      if (result.isConfirmed) {
        return true
      }
    });

  };

  warning = async (text, timer = null, timerProgressBar = null, nameButton = null) => {
    return this.messageApi.fire({
      title: "Alerta",
      html: text,
      icon: "warning",
      confirmButtonText: nameButton != null ? nameButton : 'Ok',
      timer: timer,
      timerProgressBar: timerProgressBar,
      appendToBody: true,
      heightAuto: false
    }).then((result) => {
      if (result.isConfirmed) {
        return true
      }
    });
  };

  info = (text, timer = null, timerProgressBar = null, nameButton = null) => {
    return this.messageApi.fire({
      title: "Informação",
      html: text,
      icon: "info",
      confirmButtonText: nameButton != null ? nameButton : 'Ok',
      timer: timer,
      timerProgressBar: timerProgressBar,
      appendToBody: true,
      heightAuto: false
    }).then((result) => {
      if (result.isConfirmed) {
        return true
      }
    });
  };

  question = (text, timer = null, timerProgressBar = null, confirmButtonText = null, cancelButtonText = null) => {
    return this.messageApi.fire({
      title: "Deseja continuar?",
      html: text,
      icon: "question",
      confirmButtonText: confirmButtonText != null ? confirmButtonText : 'Confirmar',
      cancelButtonText: cancelButtonText != null ? cancelButtonText : 'Cancelar',
      timer: timer,
      timerProgressBar: timerProgressBar,
      appendToBody: true,
      heightAuto: false,
      showCancelButton: true,
      showCloseButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        return true
      }
    });
  };
}
